@mixin border-radius($val) {
  border-radius: $val;
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
}

@mixin font-weight-bold {
  font-weight: 700 !important;
}

@mixin font-weight-semibold {
  font-weight: 600;
}

@mixin font-weight-medium {
  font-weight: 500;
}

@mixin font-weight-regular {
  font-weight: 400;
}

@mixin flex-basic-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-center {
  @include flex-basic-center;
  flex-direction: column;
}
